<template>
    <div class="Projectdetails" style="margin-top:20px">
        <div class="Project_icon">
            <el-tooltip content="返回上一层" placement="top-start">
                <i class="iconfont icon-fanhui" @click="returnTo()"></i>
            </el-tooltip>
        </div>
        <div class="review_button" v-show="this.$route.query.val==1">
            <el-button type="primary" size="mini" @click="dialogVisible=true">评分</el-button>
        </div>
        <div class="Project_view_div">
        <el-card class="tails_card">
            <div class="tails_card_title">
                基础信息
            </div>
            <el-row :gutter="24" style="padding:0px 15px">
                <el-col 
                    :xs="{span: 24}" 
                    :sm="{span:12}" 
                    :md="{span:8}">
                    <div class="tails_card_term">项目名称：</div>
                    <div class="tails_card_detail">{{basis.name}}</div>
                </el-col>
                <el-col 
                    :xs="{span: 24}" 
                    :sm="{span:12}" 
                    :md="{span:8}">
                    <div class="tails_card_term">项目类型：</div>
                    <div class="tails_card_detail">{{basis.type==1?'创新训练项目':basis.type==2?'创业训练项目':basis.type==3?'创业实践项目':''}}</div>
                </el-col>
                <el-col 
                    :xs="{span: 24}" 
                    :sm="{span:12}" 
                    :md="{span:8}">
                    <div class="tails_card_term">是否为青年红色筑梦之旅项目：</div>
                    <div class="tails_card_detail">{{basis.isYouth==0?'是':'否'}}</div>
                </el-col>
                <el-col 
                    :xs="{span: 24}" 
                    :sm="{span:12}" 
                    :md="{span:8}">
                    <div class="tails_card_term">申报日期：</div>
                    <div class="tails_card_detail">{{basis.declarationTime}}</div>
                </el-col>
                <el-col 
                    :xs="{span: 24}" 
                    :sm="{span:12}" 
                    :md="{span:8}">
                    <div class="tails_card_term">项目开始日期：</div>
                    <div class="tails_card_detail">{{basis.beginTime}}</div>
                </el-col>
                <el-col 
                    :xs="{span: 24}" 
                    :sm="{span:12}" 
                    :md="{span:8}">
                    <div class="tails_card_term">项目截至日期：</div>
                    <div class="tails_card_detail">{{basis.endTime}}</div>
                </el-col>
            </el-row>
            <div class="divider"></div>
            <!-- <div class="divider"></div> -->
            <div class="tails_card_title" style="margin-top:30px">
                项目综述
            </div>
            <el-form :model="review" class="demo-ruleForm" :inline="true">
                <div class="declare_form_two clearfix">
                    <!-- 创新训练项目 -->
                    <div v-show="basis.type!=2 && basis.type!=3">
                        <el-col :span="20">
                            <el-form-item prop="s1" label="一、项目简介（200 字以内）" style="width:100%">
                                <el-input type="textarea" :rows="6" disabled placeholder="请输入内容" v-model="review.s1" show-word-limit></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="s2" label="二、项目相关研究现状及发展动态（不少于 200 字）" style="width:100%">
                                <el-input type="textarea" :rows="6" disabled placeholder="请输入内容" v-model="review.s2"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="s3" label="三、项目实施的目的、意义（不少于 200 字）" style="width:100%">
                                <el-input type="textarea" :rows="6" disabled placeholder="请输入内容" v-model="review.s3"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="s4" label="四、项目研究内容和拟解决的关键问题（不少于 300 字）" style="width:100%">
                                <el-input type="textarea" :rows="6" disabled placeholder="请输入内容" v-model="review.s4"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="s5" label="五、项目研究与实施的基础条件（不少于 300 字）" style="width:100%">
                                <el-input type="textarea" :rows="6" disabled placeholder="请输入内容" v-model="review.s5"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="s6" label="六、项目实施方案（不少于 300 字）" style="width:100%">
                                <el-input type="textarea" :rows="6" disabled placeholder="请输入内容" v-model="review.s6"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="s7" label="七、项目创新点及特色（不少于 300 字）" style="width:100%">
                                <el-input type="textarea" :rows="6" disabled placeholder="请输入内容" v-model="review.s7"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="s8" label="八、已有基础（包括与本项目有关的研究积累和已取得的成绩、学校可以提供的条 件、尚缺少的条件及解决方法）" style="width:100%">
                                <el-input type="textarea" :rows="6" disabled placeholder="请输入内容" v-model="review.s8"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="s9" label="九、项目研究进度安排及各阶段预期成果" style="width:100%">
                                <el-input type="textarea" :rows="6" disabled placeholder="请输入内容" v-model="review.s9"></el-input>
                            </el-form-item>
                        </el-col>
                    </div>
                    <!-- 创业训练项目 -->
                    <div v-show="basis.type==2">
                        <el-col :span="20">
                            <el-form-item prop="r1" label="一、项目简介（200 字以内）" style="width:100%">
                                <el-input type="textarea" :rows="6" disabled placeholder="请输入内容" v-model="review.r1" show-word-limit></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="r2" label="二、项目实施的目的、意义" style="width:100%">
                                <el-input type="textarea" :rows="6" disabled placeholder="请输入内容" v-model="review.r2"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="r3" label="三、行业及市场前景（不少于 400 字）（行业历史与前景，市场规模及增长趋势，行业竞争对手，未来市场销售预测等）" style="width:100%">
                                <el-input type="textarea" :rows="6" disabled placeholder="请输入内容" v-model="review.r3"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="r4" label="四、创新点与项目特色（不少于 400 字） （项目情况，技术水平，产品或模式的创新性、先进性和独特性，竞争优势）" style="width:100%">
                                <el-input type="textarea" :rows="6" disabled placeholder="请输入内容" v-model="review.r4"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="r5" label="五、生产或运营（不少于 400 字） （生产或运营方式，材料、劳动力、设备需求，质量保证，生产成本）" style="width:100%">
                                <el-input type="textarea" :rows="6" disabled placeholder="请输入内容" v-model="review.r5"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="r6" label="六、投融资方案（不少于 400 字）" style="width:100%">
                                <el-input type="textarea" :rows="6" disabled placeholder="请输入内容" v-model="review.r6"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="r7" label="七、管理模式（不少于 400 字）（合作计划，实施方案，机构设置，人员管理，销售策略等）" style="width:100%">
                                <el-input type="textarea" :rows="6" disabled placeholder="请输入内容" v-model="review.r7"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="r8" label="八、风险预测及应对措施（不少于 400 字）" style="width:100%">
                                <el-input type="textarea" :rows="6" disabled placeholder="请输入内容" v-model="review.r8"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="r9" label="九、效益预测及阶段性成果（本栏内容为中期检查及结题答辩重要参考，明确填写 立项期间取得标志性业绩或成果，并填写未来三年至五年的销售收入、利润、资产 回报率等。不少于 300 字）" style="width:100%">
                                <el-input type="textarea" :rows="6" disabled placeholder="请输入内容" v-model="review.r9"></el-input>
                            </el-form-item>
                        </el-col>
                    </div>
                    <!-- 创业实践项目 -->
                    <div v-show="basis.type==3">
                        <el-col :span="20">
                            <el-form-item prop="t1" label="一、项目简介（200 字以内）" style="width:100%">
                                <el-input type="textarea" :rows="6" disabled placeholder="请输入内容" v-model="review.t1" show-word-limit></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="t2" label="二、项目背景" style="width:100%">
                                <el-input type="textarea" :rows="6" disabled placeholder="请输入内容" v-model="review.t2"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="t3" label="三、行业及市场前景（行业历史与前景，市场规模及增长趋势，行业竞争对手，未来市场销售预测等。不少于 400 字）" style="width:100%">
                                <el-input type="textarea" :rows="6" disabled placeholder="请输入内容" v-model="review.t3"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="t4" label="四、创业计划书" style="width:100%">
                                <el-input type="textarea" :rows="6" disabled placeholder="请输入内容" v-model="review.t4"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="t5" label="五、创新点与项目特色（不少于 400 字）" style="width:100%">
                                <el-input type="textarea" :rows="6" disabled placeholder="请输入内容" v-model="review.t5"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="t6" label="六、技术或商业模式（不少于 400 字）" style="width:100%">
                                <el-input type="textarea" :rows="6" disabled placeholder="请输入内容" v-model="review.t6"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="t7" label="七、创业过程与商业分析（不少于 400 字）" style="width:100%">
                                <el-input type="textarea" :rows="6" disabled placeholder="请输入内容" v-model="review.t7"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="t8" label="八、管理模式（不少于 400 字）" style="width:100%">
                                <el-input type="textarea" :rows="6" disabled placeholder="请输入内容" v-model="review.t8"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="t9" label="九、创业投融资计划（不少于 400 字）" style="width:100%">
                                <el-input type="textarea" :rows="6" disabled placeholder="请输入内容" v-model="review.t9"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="t10" label="十、企业成长预测（不少于 400 字）" style="width:100%">
                                <el-input type="textarea" :rows="6" disabled placeholder="请输入内容" v-model="review.t10"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="t11" label="十一、风险预测及应对措施（不少于 400 字）" style="width:100%">
                                <el-input type="textarea" :rows="6" disabled placeholder="请输入内容" v-model="review.t11"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="t12" label="十二、效益预测（本栏内容为中期检查及结题答辩重要参考, 明确填写立项期间取得 的标志性成果和业绩，并填写未来三到五年的销售收入、利润、资产回报率等，不 少于 300 字)" style="width:100%">
                                <el-input type="textarea" :rows="6" disabled placeholder="请输入内容" v-model="review.t12"></el-input>
                            </el-form-item>
                        </el-col>
                    </div>
                    <el-col :span="22">
                        <el-form-item prop="s10" :label="basis.type==3?'十三、经费预算':'十、经费预算'" style="width:100%">
                            <el-table 
                                :data="review.s10" 
                                style="width: 100%;"
                                stripe
                                show-summary
                                :summary-method="getSummaries"
                                :cell-style="{'text-align':'center'}"
                                :header-cell-style="{background:'#fafafa',color:'rgba(0,0,0,.85)','text-align':'center','line-height':'1'}"
                            >   
                                <el-table-column type="index" width="55" label="#"></el-table-column>
                                <el-table-column prop="j1" label="开支科目" width="300"></el-table-column>
                                <el-table-column prop="j2" label="预算经费（元）" width="120"></el-table-column>
                                <el-table-column prop="j3" label="主要用途"></el-table-column>
                            </el-table>
                        </el-form-item>
                    </el-col>
                    <el-col :span="20">
                        <el-form-item prop="results" :label="basis.type==3?'十四、导师推荐意见':'十一、导师推荐意见'" style="width:100%">
                            <el-input type="textarea" :rows="6" disabled v-model="basis.opinionTeacher"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="20">
                        <el-form-item prop="opinionAcademy" :label="basis.type==3?'十五、院系推荐意见':'十二、院系推荐意见'" style="width:100%">
                            <el-input type="textarea" :rows="6" disabled v-model="basis.opinionAcademy"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="20">
                        <el-form-item prop="opinionSchool" :label="basis.type==3?'十六、学校推荐意见':'十三、学校推荐意见'" style="width:100%">
                            <el-input type="textarea" :rows="6" disabled v-model="basis.opinionSchool"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11">
                        <el-form-item prop="results" label="附件" style="width:100%">
                            <a class="el-upload-list__item-name" v-for="(item,index) in fileList" :key="index" style="cursor: pointer;line-height:1.8" :href="item.response.url" target="_blank">
                                <i class="el-icon-document"></i>
                                {{item.name}}
                            </a>
                        </el-form-item>
                    </el-col>
                </div>
            </el-form>
            <!-- <div class="divider"></div>
            <div class="tails_card_title clearfix">
                <span class="fl">经费支持</span>
                <span class="fr">总金额：<span class="amountMoney">{{money}}</span> 元</span>
            </div>
            <el-table 
                :data="tableData" 
                style="width: 100%;"
                stripe
                :cell-style="{'text-align':'center'}"
                :header-cell-style="{background:'#fafafa',color:'rgba(0,0,0,.85)','text-align':'center'}"
            >   
                <el-table-column type="index" width="100" label="#"></el-table-column>
                <el-table-column prop="name" label="名称"></el-table-column>
                <el-table-column prop="money" label="金额"></el-table-column>
                <el-table-column prop="bill" label="票据">
                    <template slot-scope="scope">
                        <img :src="scope.row.bill" alt="" width="100" height="80">
                    </template>
                </el-table-column>
            </el-table> -->
        </el-card>
        </div>
        <div class="review_Popup">
            <el-dialog
                title="项目评分"
                :visible.sync="dialogVisible"
                width="450px"
                :before-close="handleClose"
                :show-close="false">
                <i class="el-icon-close closei" @click="closei()"></i>
                <el-form :model="Form" :rules="rules" ref="Form" label-width="80px" class="demo-ruleForm">
                    <el-form-item prop="score" label="分值" style="width:100%">
                        <el-input v-model.number="Form.score"></el-input>
                    </el-form-item>
                </el-form>
                <span class="dialog-footer" slot="footer">
                    <el-button size="small" type="primary" @click="addmbers('Form')">确 定</el-button>
                    <el-button size="small" @click="closei('Form')">取 消</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        var checkAge = (rule, value, callback) => {
            if (!Number.isInteger(value)) {
                callback(new Error('得分必须为数字值'));
            } else {
                if (value >= 0 && value <= 100 ) {
                    callback();
                } else {
                    callback(new Error('得分范围在 0 ~ 100 之间'));
                }
            }
            
        };
        return {
            tableData:[],
            basis:{},   //基础信息
            head:{},  //负责人
            review:{},
            fileList:[],
            dialogVisible:false,
            Form:{
                score:'',
            },
            rules:{
                score:[
                    { required: true, message: '请输入分值', trigger: 'blur' },
                    { validator: checkAge, trigger: 'blur' }
                ],
            },
        }
    },
    methods: {
        getapp(){
            this.axios.studentItemitemId({
                params:{
                    itemId:this.$route.query.itemId
                }
            }).then(res=>{
                if(res.data.code==200){
                    this.basis = res.data.data.item
                    this.review = JSON.parse(this.basis.remark)
                    this.fileList = JSON.parse(res.data.data.item.attach)
                }
            }).catch(err=>{

            })
        },
        addmbers(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.Form.itmId=this.$route.query.itemId
                    this.Form.uId = this.$store.state.uid
                    this.axios.reviewsubmit(
                        this.Form
                    ).then(res=>{
                        if(res.data.code==200){
                            this.dialogVisible = false
                            this.$refs[formName].resetFields();
                            this.$message.success('评分成功')
                            this.$router.push({name:'Notreview'})
                        }
                    }).catch(err=>{

                    })
                }else{

                }
            })
        },
        // 合计
        getSummaries(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '合计'
                    //如果是最后一列，索引为列数-1，则显示计算总和
                } else if (index === 2) {
                    const values = data.map(item => Number(item[column.property]))
                    if (!values.every(value => isNaN(value))) {
                        sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr)
                        if (!isNaN(value)) {
                            return prev + curr
                        } else {
                            return prev
                        }
                        }, 0)
                        sums[index] += ' 元';
                    } else {
                        sums[index] = ''
                    }
                //如果是除了第一列和最后一列的其他列，则显示为空
                } else {
                    sums[index] = ''
                }
            });

            return sums;
        },
        handleClose(done){

        },
        //关闭弹窗
        closei(formName){
            this.dialogVisible = false
            this.$refs[formName].resetFields();
        },
        returnTo(){
            if(this.$route.query.val==1){
                this.$router.push('Notreview')
            }else{
                this.$router.push('Hasassessed')
            }
            
        },
    },
    mounted () {
        this.getapp()
    },
}
</script>
<style scoped>
@import './../../assets/css/admin/Projectdetails.css';
.declare_form_two{
    float: left;
    padding: 0 50px;
    box-sizing: border-box;
}
</style>
<style>
.declare_form_two .el-table td{
    text-align: center;
}
.declare_form_two .el-form-item__content{
    width: 100%;
}
.declare_form_two .el-form-item__label{
    width: 100%!important;
    text-align: left!important;
}
.declare_form_two .el-textarea__inner{
    resize: none;
}
.declare_form_two .el-textarea.is-disabled .el-textarea__inner{
    color: #333;
}
.Projectdetails .el-card.is-always-shadow{
    box-shadow: none!important;
    border: none;
}
.Projectdetails .el-card__body{
    padding: 0;
}
.Projectdetails .el-col{
    padding-bottom: 16px; 
}
.review_Popup .el-dialog__header{
    padding: 0 0 0 20px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    color: #fff;
    border-bottom: 1px solid #eaecee;
    background: #409EFF;
}
.review_Popup .el-dialog__title{
    font-size: 14px;
    color: #fff;
}
.review_Popup .el-table__body-wrapper{
    min-height: 300px!important;;
    height: 300px!important;
}
.review_Popup .el-table__empty-block{
    min-height: 300px!important;;
    height: 300px!important;
}
.review_Popup .el-dialog__footer{
    text-align: center;
}
.review_Popup .el-input__inner{
    width: 300px;
}
</style>